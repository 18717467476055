.Table-Tipos-Usuarios .ant-table-thead th {
    background-color: #004fb8 !important;
    color: white !important;
}
.Table-Tipos-Usuarios .ant-table-thead th::before {
    display: none !important;
}

.Status-Color-Green{
    width: 10px;
    height: 10px;
    background-color: #1eec41;
    border-radius: 100%;
}
.Status-Color-Red{
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 100%;
}

.Table-Tipos-Usuarios .ant-table-thead th:hover {
    background-color: #004fb8 !important;
    color: white !important;
}

.Table-Tipos-Usuarios .ant-table-tbody tr td div{
    text-transform: none;
}

.Container-Form-Type-User{
    display: flex;
    flex-direction: column;
    height: calc(100% - 50px);
    justify-content: space-between;
    overflow-x: hidden;
    overflow-y: scroll;
}
.Container-Form-Type-User.Create-Edit-Type-User{
    justify-content: start !important;
}
.Container-Form-Type-User .Type-Profile-Item{
    margin-bottom: 5px;
}

.Container-Form-Type-User .Type-Profile-Item .ant-form-item-label{
    padding-bottom: 1px;
}

.Container-Button-Type-User{
    display: flex;
    justify-content: center;
    margin-bottom: 0;
}

.Container-Type-Permission-Type-User{
    display: flex;
    justify-content: space-between;
    margin: 6px 0;
    font-size: 12px;
}

.Icon-Button-Extra-Option-Type-User{
    font-size: 14px;
    margin-right: 4px;
    color: #1677ff;
}
.Button-Extra-Option-Type-User{
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.Button-Action-Type-User{
    margin-left: 10px;
    width: 100px;
}

.Container-Permissions-Type-User{
    display: flex;
    justify-content: space-between;
    font-size: 12px;
}

.Column-Left-Permissions-Type-User{
    border-right: 1px solid #EEEDED;
}