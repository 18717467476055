.Modal-Date .ant-modal-footer{
    text-align: center;
}
.Date{
    background-color: #fff;
}
.Date-Header{
    display: flex;
    justify-content: space-around;
    padding: 16px 5px;
    border-bottom: 2px solid #e1dcdc;
}
.Button-Header-Active{
    background-color: #013A81;
    color: #fff;
    border-color: #013A81;
}
.Button-Header-Active:hover{
    color: #fff !important;
    border-color: #013A81 !important;
}
.Date-Body{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: 216px;
}
.Container-Button-Date{
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
}
.Button-Date{
    border: 0;
    background-color: #fff;
    box-shadow: none;
    color: #000;
    width: 50px;
}
.Button-Date:hover, .Button-Date-Day:hover{
    color: #2AD295 !important;
    background-color: #D7FCD2;
}
.Date-Body-Day{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    row-gap: 10px;
    padding: 10px 5px;
    /* height: 216px; */
    max-height: 320px;
}
.Container-Button-Date-Day, .Button-Date-Day{
    text-align: center;
}
.Button-Date-Day{
    border: 0;
    background-color: #fff;
    box-shadow: none;
    color: #000;
}
.Button-Date.active , .Button-Date-Day.active{
    color: #2AD295 !important;
    background-color: #D7FCD2;
    font-weight: bold;
}