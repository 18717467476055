.Card-File{
    background-color: #fff !important;
    border-radius: 20px !important;
    box-shadow: rgb(216, 223, 233) 0px 0px 15px;
    border: 0 !important;
    min-height: 260px;
    height: 100%;
    position: relative;
}
.Card-File .ant-card-cover{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 26px 20px 12px 20px;
    height: 45%;
    border-radius: 20px 20px 0 0;
}
.Card-File.Card-Loading .ant-card-cover{
    height: 65%;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    background-color: white;
}
.Card-File .Image-Card-File{
    width: 80px;
    height: 80px;
    border-radius: 50% !important;
}
.Card-File .Image-Loading-Card-File{
    width: 140px;
    height: 140px;
    background-color: white;
}
.Card-File .Container-Error-Card-File{
    width: 110px;
    height: 110px;
    border-radius: 50%;
    border: 1px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Card-File .Container-Error-Body-Card-File{
    width: 80px;
    height: 80px;
    background-color: red;
    border-radius: 50%;
    border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Card-File.Card-Error .ant-card-cover{
    height: 60%;
    padding-bottom: 0;
    align-items: flex-start;
    padding-top: 20px;
}
.Card-File .ant-card-meta-avatar{
    padding-inline-end: 12px;
}

.Container-Icon-Card-File .ant-picker-input{
    display: flex !important;
    justify-content: center !important;
}
.Container-Icon-Card-File .ant-picker-input input{
    display: none;
}
.Container-Icon-Card-File .ant-picker-input .ant-picker-suffix{
    margin-inline-start: 0px !important;
}
.Card-File .Container-Icon-Card-File{
    border: 1px solid rgb(255, 128, 35);
    border-radius: 100%;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 128, 35);
    width: 28px;
    height: 28px;
}
.Card-File .Container-Icon-Card-File .Image-Icon-Card-File{
    font-size: 13px;
    color: #fff;
}
.Card-File .ant-card-body{
    padding: 14px 18px 20px 18px;
    height: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}
.Card-File.Card-Loading .ant-card-body{
    padding-top: 0;
    height: 20%;
}
.Card-File.Card-Error .ant-card-body{
    padding-top: 0;
    height: 40%;
}
.Card-File .ant-card-body::after, .Card-File .ant-card-body::before{
    display: none;
}
.Card-File .ant-card-meta-detail{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.Card-File .ant-card-meta-title{
    font-family: 'Inter';
    margin-bottom: 3px !important;
    color: #004fb8;
    font-size: 11px;
    white-space: break-spaces;
    line-height: 1.2;
}
.Card-File .ant-card-meta-description{
    font-family: 'Inter';
    font-size: 10px;
    line-height: 14.52px;
    color: #004fb8;
}
.Card-File .Container-Tag-Card-File{
    text-align: center;
    padding: 0 15px;
    font-size: 8px !important;
}
.Card-File .Linear-Tag-Card-File{
    display: block;
    height: 1px;
    background-color: var(--lead);
}
.Card-File .Tag-Card-File{
    font-family: 'Inter';
    display: flex;
    /* justify-content: space-between; */
    justify-content: center;
    align-items: center;
    margin-inline-end: 0;
    padding-inline: 0;
    background: transparent;
    border: 0;
    border-radius: 0;
    margin: 0;
    color: #004fb8 !important;
    font-size: 10px;
}
.Card-File .Tag-Card-File.Tag-Inactive{
    display: none !important;
}
.Card-File .Tag-Card-File span{
    color: #004fb8;
    margin-top: 1px;
}
.Card-File .Tag-Card-File span:hover{
    color: #004fb8;
}
.Card-File .Button-Card-File{
    display: flex;
    justify-content: center;
    margin: 0 auto !important;
    background-color: #edf0fa !important;
    border-radius: 14px;
    border: 1px solid #004fb8;
    color: #004fb8;
    width: 90px;
    height: auto;
    padding-top: 1px;
    padding-bottom: 1px;
    font-size: 10px;
}
.Card-File .Button-Card-File:hover{
    color: #004fb8 !important;
}
.Card-File.Card-Loading .Title-Loading{
    font-family: 'Inter';
    font-weight: bold;
    text-align: center;
    font-size: 12px;
}
.Card-File.Card-Loading .Text-Loading{
    font-family: 'Inter';
    text-align: center;
    font-size: 12px;
}
.Card-File.Card-Error .Title-Error{
    font-family: 'Inter';
    font-weight: bold;
    text-align: center;
    font-size: 12px;
    color: #FF3742;
}
.Card-File.Card-Error .Text-Error{
    font-family: 'Inter';
    text-align: center;
    font-size: 11px;
    color: #FF3742;
    line-height: 1;
}

.Detalle-Notificacion-Upload{
    margin-left:0px;
    color: #B40001;
    font-size: 11px;
}

.Titulo-Detalle-Notificacion-Upload{
    color: red;
    font-size: 12px;
}

.Button-Download-Template{
    border: 1px solid #29aa29;
    color: #29aa29;
    font-size: 10px;
    height: 19px;
    margin: 5px auto 0 auto;
    padding: 0;
    width: 170px;
}

.Button-Download-Template:hover, .Button-Download-Template:active{
    background-color: #29aa29 !important;
    color: #fff !important;
    border: 1px solid #29aa29 !important;
}