.Card-Notification{
    position: fixed;
    width: 20%;
    height: calc(100% - 154px);
    bottom: 46px;
    right: 44px;
    z-index: 2;
    border-radius: 0;
    transition: 1.5s;
}
.Card-Notification.Active-Notification{
    width: 100%;
    height: calc(100% - 96px);
    transition: 1.5s;
    right: 0;
}
/* .Card-Notification .ant-card-body{
    overflow: hidden;
} */
.Container-Title{
    display: flex;
    align-items: center;
}
.Icon-Arrow-Notification{
    width: 34px;
    height: 34px;
    margin-right: -2px;
    border-radius: 50%;
    padding: 7px;
    cursor: pointer;
}
.Icon-Arrow-Notification:hover{
    background-color: #EDF0FA;
}
.Icon-Bell-Notification{
    width: 20px;
    height: 20px;
    margin-left: 5px;
}
.Text-Title{
    margin-left: 3px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #004FB8;
}
.Container-Not-Notification{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.Image-Not-Notification{
    width: 50px;
}
.Text-Not-Notification{
    font-family: 'Inter';
    font-size: 14px;
    line-height: 19px;
    color: #ACAFB7;
}
.Title-Errors{
    font-family: 'Inter';
    color: #FF3742;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 2px;
    margin-top: 5px;
}
.Text-Errors{
    font-family: 'Inter';
    color: #FF3742;
    font-size: 11px;
    margin-right: 20px;
}
.Error-separate{
    width: 100%;
    height: 1px;
    background-color: #D7E8FF;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Title-Correcto-Upload{
    font-size: 12px;
    font-weight: bold;
    color: #004FB8;
    font-family: 'Inter';
}

.Text-Correcto-Upload{
    font-size: 11px;
    font-family: 'Inter';
    /* color: #004FB8; */
    margin-right: 20px;
}

.Container-Notificacion-Upload{
    position: relative;
}

.Container-Iconos-Notificaciones-Upload{
    position: absolute;
    /* background-color: green; */
    width: 20px;
    height: 20px;
    right: 0;
    top: 5px;
}

.Container-Iconos-Notificacion-Plus-Upload{
    position: absolute;
    /* background-color: green; */
    width: 20px;
    height: 20px;
    right: 0;
    top: 25px;
}

.Icon-Notificacion-Upload{
    color: red;
    display: flex;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
}

.Icon-Plus-Notificacion-Upload{
    color: #1677FF;
    display: flex;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
}