.Sidebar-Menu .ant-menu .ant-menu-title-content{
    font-size: 10.5px;
}
.Sidebar-Menu .ant-menu-item-group-title{
    font-size: 10.5px;
    padding-left: 12px;
}

.Sidebar-Menu .ant-menu-submenu .ant-menu-item {
    margin-bottom: 5px !important;
    padding-left: 35px !important;
}
.Sidebar-Menu .ant-menu-item {
    margin: 1px 4px!important;
    padding-left: 15px !important;
    height: 33px;
}
.Sidebar-Menu .ant-menu-item-group-list .ant-menu-submenu-title{
    padding-left: 15px !important;
}
.Sidebar-Menu .ant-menu-submenu .ant-menu-submenu-title{
    height: 32px;
    line-height: 32px;
}
.uploadrestriccion .ant-modal-title{
    text-align: center;
    font-size: 15px;
}
.uploadrestriccion__text{
    font-size: 14px;
    margin-bottom: 10px;
}
.uploadrestriccion .ant-modal-footer{
    text-align: center;
}
.uploadrestriccion__item .ant-form-item-extra{
    font-size: 12px;
    color: #000;
}